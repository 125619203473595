import React, {  useEffect, useMemo, useRef, useState } from "react";
import {  useNavigate } from 'react-router-dom';
import { Dropdown, DropdownRef, List, Modal, Tabs, Toast } from "antd-mobile";
import { getManagerBetList, getNotification, getVersion } from "../../axios/index.js";
import { PlayType } from "../../constant/betPlayType.ts";
import { cnNameMap } from "../../constant/cnNameMap.ts";
import { getTime } from "../../utils/index.ts";
import Footer from "../../components/ManagerFooter";
import Countdown from 'react-countdown';
import FootballPNG from "../../assets/407.png";
import BasketballPNG from "../../assets/406.png";
import BeijingPNG from "../../assets/405.png";
import PickThreePNG from "../../assets/202.png";
import ChampionPNG from "../../assets/champion.png";
import "./Home.css";

function BetInfo(props: any) {
    const { bet } = props;
    const groupBuyTask = bet.groupBuyTask;
    const username = bet.userId.remark || bet.userId.username;
    const userBuyTime = bet.userBuyTime;
    const closeTime = bet.closeTime;
    const showCountDown = useMemo(() => (closeTime > Date.now() && closeTime - Date.now() < 60 * 60 * 1000), [closeTime])
    const countDownRender = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          return <span>已截止</span>;
        } else {
          return <span>截止：{hours}:{minutes}:{seconds}</span>;
        }
      };
    return (
        <div style={{ display: "flex", marginBottom: "10px", padding: "10px", fontSize: "13px", backgroundColor: "white", borderRadius: "3px" }}>
            <div style={{ marginRight: "10px" }}>
            {
                (bet.type === "football") && (<img src={FootballPNG} alt="" style={{ width: "40px" }}/>)
            }
            {
                (bet.type === "beijing") && (<img src={BeijingPNG} alt="" style={{ width: "40px" }}/>)
            }
            {
                (bet.type === "basketball") && (<img src={BasketballPNG} alt="" style={{ width: "40px" }}/>)
            }
            {
                (bet.type === "pickThree") && (<img src={PickThreePNG} alt="" style={{ width: "40px" }}/>)
            }
            {
                (bet.type === "champion") && (<img src={ChampionPNG} alt="" style={{ width: "40px" }}/>)
            }
            
            </div>
            <div style={{ flex: 1 }}>
                <span>
                    {cnNameMap[bet.type as string]} {cnNameMap[bet.subType]}
                    {
                        bet.playType === PlayType.PublishFollowBuy && (
                            <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>发单</span>
                        )
                    }
                    {
                        bet.playType === PlayType.FollowBuy && (
                            <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>跟单</span>
                        )
                    }
                    {
                        bet.playType === PlayType.GroupBuy && (
                            <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>合买</span>
                        )
                    }
                    {
                        bet.playType === PlayType.ChaseNum && (
                            <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>追号</span>
                        )
                    }
                    {
                        bet.playType === PlayType.BonusOptimization && (
                            <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>奖金优化</span>
                        )
                    }
                    {
                        bet.playType === PlayType.Normal && (
                            <span style={{ border: "solid 1px #ff0000", color: "#ff0000", marginLeft: "10px", fontSize: "13px", padding: "1px 5px" }}>自购</span>
                        )
                    }
                </span> <br />
                <span>下单用户：</span> { username } <br />
                <span>下单时间：</span> { getTime(userBuyTime) } <br />
                <span>截止时间：</span> { getTime(closeTime) } <br />
            </div>
            <div style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}>
                <div style={{ color: "red" }}>{bet.spend}元</div>
                {
                    bet.maxSP > 100 ? (
                        <div style={{ color: "red", padding: '0 5px', border: 'solid' }}>SP: {bet.maxSP}</div>
                    ) : (
                        <div>SP: {bet.maxSP}</div>
                    )
                }
                {
                    bet.playType === PlayType.GroupBuy && (
                        <div>
                            <span style={{color: "red"}}>{`${(groupBuyTask?.totalPurchasedCopies / groupBuyTask?.totalCopies * 100).toFixed(0)}%`}</span>
                            {groupBuyTask?.guaranteedCopies ? `+保${(groupBuyTask?.guaranteedCopies / groupBuyTask?.totalCopies * 100).toFixed(0)}%` : ""}
                        </div>
                    )
                }
                <div>
                    <button style={{
                        display: "inline-block",
                        width: "60px",
                        height: "25px",
                        background: "#008ae6",
                        color: "white",
                        borderRadius: "3px",
                        fontSize: '15px',
                    }}>接单</button>
                </div>
                {
                    showCountDown && (
                        <div>
                            <Countdown date={closeTime} renderer={countDownRender}/>
                        </div>
                    )
                }
            </div>
        </div>
    )
};
const sortType2CN = {
    closeTime: "截止时间",
    userBuyTime: "下单时间",
    amount: "下单金额"
}

const type2CN = {
    "football,basketball,beijing,pickThree,champion": "全部彩种",
    "football": "竞彩足球",
    "basketball": "竞彩篮球",
    "beijing": "北京单场",
    "pickThree": "排列三",
}

export default function Home() {
    const ref = useRef<DropdownRef>(null);
    const navigate = useNavigate();
    const [noOrderBetList, setNoOrderBetList] = useState([]);
    const [noTicketBetList, setNoTicketBetList] = useState([]);
    const [groupBuyBetList, setGroupBuyBetList] = useState([]);
    const [timmerRecord, setTimmerRecord] = useState(0);
    const [sortType, setSortType] = useState("closeTime");
    const [status, setStatus] = useState("0");
    const [type, setType] = useState("football,basketball,beijing,pickThree,champion");
    useEffect(() => {
        const lastUpdate = Number(localStorage.getItem('_ut'));
        const now = Date.now();
        if(lastUpdate + 1 * 60 * 60 * 1000 < now) {
            localStorage.setItem('_ut', now.toString());
            getVersion({ ut: now }).then(({data}) => {
                if(data.version !== localStorage.getItem('_v')) {
                    localStorage.setItem('_v', data.version);
                    window.location.reload();
                }
            });
            getNotification({ ut: now }).then(({data}) => {
                if(data.version !== localStorage.getItem('_n')) {
                    localStorage.setItem('_n', data.version);
                    Modal.alert({
                        content: data.text,
                    })
                }
            })
        }
    }, []);
    useEffect(() => {
        getManagerBetList({status, sortType, type}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                if(status === "0") {
                    setNoOrderBetList(data.betList);
                }
                if(status === "1") {
                    setNoTicketBetList(data.betList);
                }
                if(status === "7") {
                    setGroupBuyBetList(data.betList);
                }
            }
        });
        const timmer = setTimeout(() => { setTimmerRecord((timmer) => timmer+1) }, 5 * 1000);
        return () => clearTimeout(timmer);
    }, [status, type, sortType, timmerRecord])
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }} className="bg-gray manager-home">
            <Dropdown style={{ color: "white" }} closeOnClickAway={true} ref={ref}>
                <Dropdown.Item key='sorter' title={sortType2CN[sortType]}>
                    <List header=''>
                        <List.Item onClick={() => { setSortType("closeTime"); ref.current?.close(); }}>截止时间</List.Item>
                        <List.Item onClick={() => { setSortType("userBuyTime"); ref.current?.close(); }}>下单时间</List.Item>
                        <List.Item onClick={() => { setSortType("amount"); ref.current?.close(); }}>金额排序</List.Item>
                    </List>
                </Dropdown.Item>
                <Dropdown.Item key='bizop' title={type2CN[type]}>
                    <List header=''>
                        <List.Item onClick={() => { setType("football,basketball,beijing,pickThree,champion"); ref.current?.close(); }}>全部彩种</List.Item>
                        <List.Item onClick={() => { setType("football"); ref.current?.close(); }}>竞彩足球</List.Item>
                        <List.Item onClick={() => { setType('basketball'); ref.current?.close(); }}>竞彩篮球</List.Item>
                        <List.Item onClick={() => { setType('beijing'); ref.current?.close(); }}>北京单场</List.Item>
                        <List.Item onClick={() => { setType('pickThree'); ref.current?.close(); }}>排列三</List.Item>
                    </List>
                </Dropdown.Item>
            </Dropdown>
            <Tabs onChange={(key) => setStatus(key)} defaultActiveKey="0">
                <Tabs.Tab title='未接单' key='0' />
                <Tabs.Tab title='未出票' key='1' />
                <Tabs.Tab title='合买' key='7' />
            </Tabs>
            <div style={{ flex: "1 1", overflow: "auto" }}>
                {
                    status === "0" && noOrderBetList.map((bet: any) => (
                        <div key={bet._id}
                            onClick={() => navigate(`/manager/bet/detail/${bet._id}`)}>
                                <BetInfo bet={bet}/>
                        </div>
                    ))
                }
                {
                    status === "1" && noTicketBetList.map((bet: any) => (
                        <div key={bet._id}
                            onClick={() => navigate(`/manager/bet/detail/${bet._id}`)}>
                                <BetInfo bet={bet}/>
                        </div>
                    ))
                }
                {
                    status === "7" && groupBuyBetList.map((bet: any) => (
                        <div key={bet._id}
                            onClick={() => navigate(`/manager/bet/detail/${bet._id}`)}>
                                <BetInfo bet={bet}/>
                        </div>
                    ))
                }
            </div>
            <Footer />
        </div>
    )
}